import React from "react"
import { Link } from "gatsby"
import Contact from "../components/contact"
import Footer from "../components/footer"
import Navbar from "../components/navbar"
import ImgSpace from "../images/space.png"

const NotFoundPage = () => {
  return (
    <div>
      <a href="#" className="scrolltop" id="scroll-top">
        <i className="bx bx-chevron-up scrolltop__icon"></i>
      </a>
      <Navbar />
      <div className="notfound__container bd-container">
        <img src={ImgSpace} className="notfound__img" />
        <div className="notfound">
          <Link to="/" className="notfound__button">Back to Homepage</Link>
        </div>
      </div>
      <Contact />
      <Footer />
    </div>

  )
}

export default NotFoundPage
